/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: segoe;
    src: url("/segoe-ui-this/segoeuithis.ttf") format("opentype");
}
* {
    font-family: "segoe", "arial", sans-serif;
}

html,
body {
    min-height: 100vh;
    margin: 0;
    overflow: hidden;
}
.ddp-report {
    overflow: hidden;
    iframe {
        min-height: 100vh;
        border: none;
    }
}
